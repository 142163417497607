.hambuger-hotspot {
  position: absolute;
  top: 0;
  right: 0;
  width: 3%;
  height: 6%;
  z-index: 1;
  cursor: pointer;
}
.references-hotspot {
  position: absolute;
  top: 0;
  right: 4%;
  width: 8%;
  height: 6%;
  z-index: 1;
  cursor: pointer;
}
.glossary-hotspot {
  position: absolute;
  top: 0;
  right: 12%;
  width: 11%;
  height: 6%;
  z-index: 1;
  cursor: pointer;
}
.hamburger-container {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  height: 50%;
  z-index: 3;
}

.menu-open {
  display: block !important;
}

.menu-wrapper {
  text-align: left;
}

.menu-item {
  background-color: #3d3d3d;
  padding: 10px 10px;
  border-bottom: 1px solid grey;
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  min-width: 260px;
}

.toggle-content {
  background-color: #3d3d3d;
  border-bottom: 1px solid grey;
  font-size: 1.5em;
}

.toggle-content-item {
  border-bottom: 1px solid grey;
  padding: 5px 5px;
  padding-left: 30px;
}

.toggle-content-item:hover {
  cursor: pointer;
  background-color: rgba(43, 43, 43);
}

.toggle-content-item:last-child {
  border-bottom: none;
}

.menu-item.bubbles {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 20px;
  background: linear-gradient(to right, #303030, #262626);
}

.menu-item:hover {
  cursor: pointer;
  background-color: rgba(43, 43, 43);
}

.menu-item:last-child {
  border-bottom: none;
}

.reference-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 90%;
  height: 80%;
}

.references-modal-container {
  background-color: rgb(54, 54, 54);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  width: 100%;
  height: 90%;
}

.references-inner-container {
  border-radius: 10px;
  color: white;
  font-size: 1.7499em;
  height: 100%;
  overflow: auto;
}
.reference-table {
  border-spacing: 0px;
  max-width: 87%;
}

.anemia-table {
  border-spacing: 12px;
  max-width: 87%;
}

.reference-header {
  font-weight: 900;
  font-size: 1em;
}

.reference-row {
  vertical-align: top;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  font-size: 12px;
}

.glossary-row {
  vertical-align: top;
  padding: 0px;
  margin: 10px 0px;
  font-size: 12px;
}

.reference-row sup {
  font-size: 12px;
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

.reference-modal-close {
  position: absolute;
  top: -40px;
  right: -10px;
  font-size: 28px;
  width: 25px;
  cursor: pointer;
  background-color: rgba(113, 113, 113, 0.922);
  padding: 10px;
  border-radius: 25px;
}

.training-container {
  font-size: 12px;
  color: white;
}

#wirewax-player1 {
  display: none;
}
#wirewax-player2 {
  display: none;
}
#wirewax-player3 {
  display: none;
}

.tippy-box[data-theme~='custom-grey-black'] {
  background-color: rgba(175, 175, 175, 0.96);
  color: black;
  padding: 5%;
  font-size: 0.8em;
  box-shadow: #262626;
}

.tippy-box[data-theme~='custom-grey-black'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: rgba(175, 175, 175, 0.96);
}
.tippy-box[data-theme~='custom-grey-black'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: rgba(175, 175, 175, 0.96);
}
.tippy-box[data-theme~='custom-grey-black'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: rgba(175, 175, 175, 0.96);
}
.tippy-box[data-theme~='custom-grey-black'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: rgba(175, 175, 175, 0.96);
}
