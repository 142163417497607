.blue-bg {
  background-color: #2c5464;
}

.green-text {
  color: #7fbc42;
}

.bullets {
  align-self: flex-start;
  padding-left: 0;
  list-style-position: inside;
  list-style-type: disc;
  margin: 0;
  margin-bottom: 4px;
}

.test-container {
  font-size: 0.822em;
  font-weight: 900;
  margin: 1.25% 0 0 0;
  text-align: center;
}

.smaller {
  font-size: 0.786em;
  font-weight: 700;
}

.home-container {
  display: flex;
  flex-direction: column;
  font-size: 1.7499em;
  max-width: 1600px;
  text-align: left;
  gap: 1.686em;
  width: 100%;
  height: 100%;
}
.home-container .training-container {
  align-self: flex-end;
  font-size: 0.6em;
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}
.swiper-container {
  position: relative;
  visibility: hidden;
}
.swiper-container.show-swiper {
  visibility: visible;
}
.swiper-slide img {
  width: 100%;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 30px;
}
.swiper-button-prev {
  left: 20%;
}
.swiper-button-next {
  right: 20%;
}
.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination {
  bottom: -20px;
}
.swiper-pagination-bullet {
  background: transparent;
  border-radius: 50%;
  border: 2px solid white;
}
.swiper-pagination-bullet-active {
  background: white;
}
@media screen and (max-width: 800px) {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 24px;
  }
}
@media screen and (max-width: 600px) {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 18px;
  }
}

:root {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
}

.home-learn-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-learn-more .learn-more-caption {
  text-align: center;
}

.home-learn-more .learn-more-container {
  align-self: center;
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  padding: 1.072% 0.715%;
  width: 40%;
  margin-top: 10px;
}
.home-learn-more .learn-more-container .learn {
  flex: 1;
  font-size: 0.643em;
  font-weight: 900;
  text-align: center;
}

.home-learn-more .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 54%;
}
.home-learn-more .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.home-learn-more .modal-container {
  background-color: #7c7d81;
  border-radius: 10px;
  height: 50%;
  padding: 30px;
  position: relative;
}

.modal-title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin-bottom: 10px;
}

.anemia-modal-title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 6px 0px 6px 0px;
}

.home-modal-inner-container {
  background-color: white;
  border-radius: 10px;
  color: #7c7d81;
  font-size: 1.7499em;
}
.anemia-modal-inner-container {
  background-color: white;
  color: #7c7d81;
  font-size: 1.7499em;
  height: 90%;
  width: 98%;
  overflow: auto;
}
.modal-image {
  max-width: 100% !important;
  height: auto;
  width: auto;
}

.home-learn-more .modal-close {
  position: absolute;
  top: 3px;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
}
