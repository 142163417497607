.piechart-container {
  display: flex;
  flex-direction: column;
  font-size: 1.7499em;
  max-width: 1600px;
  text-align: left;
  /* gap: 1.686em; */
  width: 100%;
  /* height: 100%; */
  height: calc(100% - 50px);
  width: 80%;
}

.piechart-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto -3px auto;
  width: 100%;
}

.piechart-img {
  position: relative;
  aspect-ratio: 16/9;
  height: unset;
  width: 70%;
}

.piechart-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.hotspot-green {
  content: '';
  position: absolute;
  bottom: 8%;
  right: 3%;
  width: 26%;
  height: 44%;
  border-bottom-right-radius: 100%;
  width: 26%;
  height: 44%;
}

.hotspot-orange {
  z-index: 1000;
  position: absolute;
  top: 9%;
  right: 35%;
  width: 7%;
  height: 44%;
  transform: rotate(314deg);
}

.hotspot-orange-learnmore {
  z-index: 1000;
  position: absolute;
  top: 52%;
  left: 0%;
  width: 14%;
  height: 8%;
}

.hotspot-green-learnmore {
  z-index: 1000;
  position: absolute;
  top: 20%;
  left: 0%;
  width: 14%;
  height: 7%;
}

.piechart-column {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30%;
}

.piechart-column-item-button {
  background-color: rgb(19, 96, 89);
  color: white;
  font-weight: bold;
  padding: 10px 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  max-width: 85%;
  margin-top: 10px;
}

/* make the following on over  */
.piechart-column-item-button:hover {
  background-color: rgb(19, 96, 89, 0.8);
  cursor: pointer !important;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
}

.button-row .button-item {
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  padding: 18px 0 18px 0;
  position: relative;
  border-radius: 6px;
  font-weight: bold;
}

.button-row .button-item svg {
  position: absolute;
  right: 2%;
  max-width: 3%;
}

.button-green {
  background-color: #4f6e31ad;
}

.button-blue {
  background-color: #0d5d7cb3;
}

/* make the following on click :hover */
.button-blue:hover {
  background-color: #0d5d7cd3;
  cursor: pointer !important;
}

.button-green:hover {
  background-color: #4f6e31d5;
  cursor: pointer !important;
}

.piechart-container .training-container {
  align-self: flex-end;
  font-size: 0.6em;
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

.piechart-column-item-desc {
  font-weight: 500;
  max-width: 260px;
}
