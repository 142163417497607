.button-wall-container {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;
}

.button {
  background-color: #707070;
  opacity: 0.5;
  padding: 0.532%;
  text-align: center;
  flex-grow: 1;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 800;
}
.button-wall-bottom {
  margin-top: auto;
}

.clicked-button {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 1.33%;
  opacity: 1;
}
.close-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 10px;
  padding-bottom: 3px;
  padding-right: 3px;
}
.close-button {
  background-color: grey;
  border-radius: 20px;
  padding: 5px;
  width: 2.13%;
  margin: 10px 0px;
  z-index: 9999;
}
.close-button:hover {
  cursor: pointer;
}
