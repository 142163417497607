.landing-container {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1.7499em;
  max-width: 1400px;
  text-align: left;
  width: 100%;
  gap: 0.893em;
  height: 100%;
}
.landing-container .topsection-container {
  display: flex;
  gap: 0.893em;
  flex-direction: column;
  max-width: 710px;
  width: 100%;
}
.landing-container .title-container {
  font-size: 1em;
  font-weight: 200;
  text-align: center;
}
.landing-container .title-container .big {
  font-weight: 900;
}
.landing-container .bullets {
  align-self: flex-start;
  padding-left: 0;
  list-style-position: inside;
  list-style-type: disc;
}

.landing-container .bullets li {
  padding-left: 0;
}

.landing-container .slides-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.357em;
  text-align: center;
  width: 100%;
  margin-top: 1.429%;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 1440px) {
  .landing-container .slides-buttons-container {
    width: 94%;
  }
}
.landing-container .slides-buttons-container .slides-container {
  width: 100%;
}
.landing-container .slides-buttons-container .slides-container .header {
  font-size: 0.786em;
  font-weight: 900;
}
.landing-container .slides-buttons-container .slides-container .header .green {
  color: #7fbc42;
}
.landing-container .slides-buttons-container .slides-container .groups-container {
  display: flex;
  gap: 0.714em;
  margin-top: 0.714em;
}
.landing-container .slides-buttons-container .slides-container .group {
  background-color: #505050;
  border-radius: 10px;
  color: white;
  flex: 1;
  font-size: 0.786em;
  font-weight: 900;
  padding: 0 0.71% 0.71% 0.71%;
  text-decoration: none;
}
.landing-container .slides-buttons-container .slides-container .group .spotlight-img {
  width: 65% !important;
}

.landing-container .slides-buttons-container .slides-container .group img {
  width: 100%;
}
.landing-container .slides-buttons-container .slides-container .group .name {
  margin: 10px 0;
}

.landing-container .landing-footer {
  align-self: flex-end;
  font-size: 0.6em;
  padding: 10px;
}

.landing-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.714em;
  width: 100%;
  margin-top: 0.85%;
  justify-content: center;
}
.landing-button-container {
  align-items: center;
  background-color: #2c5464;
  border-radius: 10px;
  color: white;
  display: flex;
  flex: 1 1 35%;
  padding: 0 10px;
  height: 2.857em;
  text-decoration: none;
  max-width: 690px;
  cursor: pointer;
}
.margin-top-10 {
  margin-top: 10px;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-reverse {
  flex-direction: row-reverse;
}

.button-stack {
  flex-direction: column;
  align-items: center;
}

.button-stack .landing-button-container {
  flex: unset !important;
  width: 100%;
}

.landing-container .slides-buttons-container .landing-buttons-container .landing-button-container {
  flex: 1;
}
.landing-container
  .slides-buttons-container
  .landing-buttons-container.printer
  .landing-button-container {
  flex: 1 1 35%;
}
.landing-button-container.wall {
  background-color: #4c4c4c;
}
.landing-button-container .name {
  flex: 1;
  font-size: 0.822em;
  font-weight: 900;
  text-align: center;
}
.landing-button-container .chevron {
  padding: 0 10px;
}
.laboratory-container {
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1.75em;
  gap: 1.786em;
  /* max-width: 1000px; */
  max-width: 70%;
  text-align: left;
}
.laboratory-container .landing-buttons-container,
.landing-container .landing-buttons-container.printer {
  justify-content: center;
}
.landing-container .landing-buttons-container.printer {
  width: 76%;
}
.landing-container .learn-more-container {
  align-self: center;
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  padding: 1.072% 0.715%;
  width: 40%;
  align-items: center;
}
.landing-container .learn-more-container .learn {
  flex: 1;
  font-size: 0.643em;
  font-weight: 900;
  text-align: center;
}

.modal-overlay-anemia {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.higher-modal {
  z-index: 99 !important;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 54%;
}

.modal-anemia {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 96%;
  height: 92%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.modal-container {
  background-color: #7c7d81;
  border-radius: 10px;
  height: 50%;
  padding: 30px;
  position: relative;
}

.anemia-modal-container {
  background-color: #363636;
  border-radius: 10px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.modal-inner-container {
  background-color: white;
  border-radius: 10px;
  color: #7c7d81;
  padding: 18%;
  font-size: 1.7499em;
}
.modal-close {
  position: absolute;
  top: 3px;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
}

.modal-close-x {
  position: absolute;
  top: -12px;
  right: -4px;
  width: 30px;
  cursor: pointer;
}

.anemia-modal-close {
  position: absolute;
  background-color: #363636;
  border-radius: 30px;
  top: -20px;
  right: 0px;
  font-size: 28px;
  cursor: pointer;
  box-shadow: black 0px 0px 16px 2px;
  height: 5%;
  width: 3%;
}
.button-column .landing-button-container {
  flex: unset;
}
.laboratory-container .landing-buttons-container .landing-button-container.full {
  max-width: unset;
  /* height: 120px; */
  max-height: 120px;
  width: 80%;
}
.laboratory-container .landing-buttons-container {
  margin-top: 20px;
  align-self: center;
}
.landing-button-container.bigger {
  height: 120px;
}

.grey-content-container {
  background-color: #707070;
  padding: 4px;
}

.grey-content-container p {
  margin: 0 auto;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
