.ish-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.064%;
  column-gap: 3.12em;
}

li {
  list-style-type: disc;
}

.right-content {
  text-align: left;
  width: 100%;
}

.ish-content-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 5px;
  flex: 1 1 30%;
}

.ish-content-centered {
  align-items: center;
}

.subheading-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  text-align: left;
}

.footer-container {
  display: flex;
  align-items: flex-end;
  font-size: 1.062em;
  padding: 5px 1.064% 10px 1.064%;
  flex: 1;
}

.ish-image {
  max-width: 400px;
  width: 100%;
}

.right-headline {
  font-weight: 700;
  font-size: 2em;
}

.right-centered-list {
  padding-left: 14px;
}

.blue-background {
  background-color: #2c5464;
  border-radius: 15px;
  padding: 1.44%;
  max-width: 1000px;
  font-weight: bold;
  font-size: 1.374em;
}

.accordion-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;
}

.accordion-heading {
  border-top: 1px solid white;
  padding: 8px 0;
  font-weight: bold;
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
}
.accordion-heading span {
  font-weight: normal;
  font-style: italic;
}

.accordion-heading:hover {
  cursor: pointer;
}

.waterfall-heading {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 8px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.waterfall-heading span {
  color: greenyellow;
}

.waterfall-heading:hover {
  cursor: pointer;
}

.green-text {
  color: #7fbc42;
}

.plusMinus {
  color: #ecf0f1;
  width: 25px;
  height: 25px;
  border: 0;
  font-size: 1em;
  position: relative;
}

.plusMinus span {
  position: absolute;
  transition: 300ms;
  background: white;
  border-radius: 2px;
}

.plusMinus span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.plusMinus span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}

.activeMinus span {
  transform: rotate(90deg);
}

.activeMinus span:last-child {
  left: 50% !important;
  right: 50% !important;
}
