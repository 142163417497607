.lung-wall-container {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.lung-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 4px;
}

.lung-button {
  background-color: #707070;
  opacity: 0.5;
  padding: 1%;
  text-align: center;
  flex-grow: 1;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 800;
}

.one-lung-button {
  background-color: #707070;

  padding: 0.5%;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 800;
  font-size: 16px;
  width: 90%;
  margin: 0 auto;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.lung-clicked-button {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 1.33%;
  opacity: 1;
}

.move-down-close {
  margin-bottom: -30px;
}

.lung-wall-bottom {
  margin-top: auto;
}

.lung-tab {
  background-color: #707070;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lung-tab-content {
  display: flex;
  justify-content: flex-start;
  height: 96%;
  width: 100%;
  position: relative;
}

.lung-tab-left {
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.lung-tab-right {
  width: 56%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.lung-image {
  width: calc(100% - 80px);
  max-width: 600px;
  max-height: 100%;
  object-fit: contain;
  padding: 40px;
  padding-bottom: 0px;
}

.lung-tab-content table {
  text-align: left;
  margin: 10px 0;
}

.lung-tab-content table tr {
  font-size: 1.5em;
  text-align: left;
  margin: auto 0;
}

.lung-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.lung-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  z-index: 1;
}
.lung-modal-container {
  position: relative;
  height: 100%;
  width: 100%;
  aspect-ratio: 16/9;
}

.lung-modal-container img {
  width: 100%;
  height: 100%;
}

.lung-modal-close {
  position: absolute;
  top: -30px;
  right: 10px;
  cursor: pointer;
}

.lung-modal-close img {
  width: 50px;
  height: 50px;
}

.report-image {
  max-width: 98% !important;
  height: auto;
  width: auto;
}

.lung-wall-container .training-container {
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  padding-right: 10px;
}
