.ish-tab {
  background-color: #707070;
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ish-tab-right {
  text-align: left;
  padding-right: 25px;
}

ul {
  list-style-type: circle;
}

.ish-tab.printer-tab {
  min-height: unset;
}

.ish-content ul {
  list-style-position: inside;
  padding-left: 0;
}