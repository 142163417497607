.bolded-header {
  font-weight: bold;
  margin-bottom: 1.453%;
  font-size: 1.625em;
}
.bolded-list-title {
  font-weight: bold;
  margin-bottom: 0px;
}

.slide-list {
  margin: 0px;
  padding-left: 14px;
}

.button-wall-container .topsection-container {
  display: flex;
  gap: 0.893em;
  flex-direction: column;
  font-size: 1.7499em;
  text-align: left;
  align-self: center;
}

.button-wall-container {
  margin: 0 auto;
}

.button-wall-container .bullets {
  max-width: 1200px;
  list-style-position: inside;
  padding-left: 0;
}

.button-wall-container .bullets li {
  padding-left: 0;
}

.button-wall-container .title-container {
  font-size: 1em;
  font-weight: 200;
  text-align: center;
}
