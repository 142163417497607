@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
  color: white;
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

#root {
  align-items: center;
  /*background-image: url('./images/Black\ screen\ w\ grey_1920x1080.png');
  background-position: center;
  background-size: cover;*/
  background-color: black;
  display: flex;
  text-align: center;
}

.app {
  margin: auto;
  min-width: 0;
}

.title {
  font-size: 1.286em;
  font-weight: 900;
  text-align: center;
}
.subtitle {
  font-weight: 300;
  font-size: 1.286em;
}
.bar {
  border-radius: 10px;
  font-size: 0.715em;
  font-weight: 900;
  padding: 0.715% 2.5%;
}

.lab-footer {
  font-size: 0.875em;
  padding: 10.53% 0px 0px 60px;
  text-align: left;
}

.wirewax-container {
  position: relative;
  align-self: flex-start;
  aspect-ratio: 16/9;
  margin: auto;
  font-size: 16px;
}

.wirewax-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.assay-container {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  height: 86%;
  width: calc(100% - 20px);
  z-index: 1;
  display: none;
  overflow: auto;
}

.assay-container.overlayOpen {
  display: block;
}

ul.bullets li {
  font-size: 1em;
}
